// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I4t4GQRd9OTm29DYETBg {\n    width: 25%;\n    display: inline-block;\n    text-align: center;\n    vertical-align: top;\n    cursor: pointer;\n}\n\n.HGCLv3O9_NpaO1XZqaQI {\n    margin-top: 12px;\n    width: 46%;\n    aspect-ratio: 1;\n}\n\n.pjqiRJxD6SVXCi1pgLhD div {\n    background-color: white;\n    color: black;\n    border: 1px solid lightgray;\n}\n\n.hx3f_KxnfjH_2lkbmeIw {\n    font-size: 9px;\n    margin-top: 8px;\n\n}", "",{"version":3,"sources":["webpack://./src/taskpane/components/pages/oneGateway/OneGateway_components/OneGatewayItem.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,qBAAqB;IACrB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,cAAc;IACd,eAAe;;AAEnB","sourcesContent":[".oneGatewayItem {\n    width: 25%;\n    display: inline-block;\n    text-align: center;\n    vertical-align: top;\n    cursor: pointer;\n}\n\n.oneGatewayItemImg {\n    margin-top: 12px;\n    width: 46%;\n    aspect-ratio: 1;\n}\n\n.oneGatewayHint div {\n    background-color: white;\n    color: black;\n    border: 1px solid lightgray;\n}\n\n.oneGatewayTitle {\n    font-size: 9px;\n    margin-top: 8px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"oneGatewayItem": "I4t4GQRd9OTm29DYETBg",
	"oneGatewayItemImg": "HGCLv3O9_NpaO1XZqaQI",
	"oneGatewayHint": "pjqiRJxD6SVXCi1pgLhD",
	"oneGatewayTitle": "hx3f_KxnfjH_2lkbmeIw"
};
export default ___CSS_LOADER_EXPORT___;
