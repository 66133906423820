import { initializeIcons } from "@fluentui/font-icons-mdl2";
import * as React from "react";
import * as ReactDOM from "react-dom";

import MainPage from "./components/pages/main/MainPage";

import './taskpane.css';


initializeIcons();

let isOfficeInitialized = false;

const title = "Contoso Task Pane Add-in";


const render = () => {
  ReactDOM.render(
    <div>
      <MainPage></MainPage>
    </div>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render();
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render();
  });
}
