import Tooltip from "@material-ui/core/Tooltip";
import React, { useRef, useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';

import styles from "./OneGatewayItem.module.css";
import axios from "axios";

export interface Props {
  icon?: string;
  hint?: string;
  url?: string;
  title?: string;
  target?: string;
  userProfile?: [];
}

function logging(functionName, userProfile) {
  console.log("userProfile = " + JSON.stringify(userProfile));
  var body = {
    App_name: "LF One Gateway",
    App_group: "AI WebApps",
    App_ownerteam: "CropApps",
    App_deployment: "PRD",
    Function_group: "LF AI Assistant",
    Function_name: functionName,
    User_id: userProfile[2],
    User_name: userProfile[0],
    User_email: userProfile[1]
  };

  axios({
    method: "post",
    url: "https://lfaihub.lfapps.net/api/util-Logging/v1",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      "Ocp-Apim-Subscription-Key": "f5dff5b5b751453cbdca9d3e4ff9acc1",
    },
    data: JSON.stringify(body),
  })
    .then(() => { })
    .catch((err) => {
      console.error(err);
    });
}

const handleClick = (url, target, functionName, userProfile) => {
  logging(functionName, userProfile);
  window.open(url, target);
}

export default class OneGatewayItem extends React.Component<Props> {


  
  render() {
    const { icon, hint, url, title, target, userProfile } = this.props;

    return (
      <div className={styles.oneGatewayItem} onClick={() => handleClick(url, target, title, userProfile)}>
        <Tooltip classes={{ popper: styles.oneGatewayHint }} title={hint}>
          <img className={styles.oneGatewayItemImg} src={icon} />
        </Tooltip>
        <div className={styles.oneGatewayTitle}>{title}</div>
      </div>
    );
  }
}