// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../node_modules/bootstrap/dist/js/bootstrap.js", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("./taskpane.css", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../node_modules/bootstrap/dist/css/bootstrap.css", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../../node_modules/toastr/build/toastr.css", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var code = " <!doctype html> <html lang=\"en\" data-framework=\"typescript\"> <head> <meta charset=\"UTF-8\"/> <meta http-equiv=\"X-UA-Compatible\" content=\"IE=Edge\"/> <meta name=\"viewport\" content=\"width=device-width,initial-scale=1\"> <title>Contoso Task Pane Add-in</title> <" + "script src=\"https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js\"><" + "/script> <" + "script src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"><" + "/script> <link rel=\"stylesheet\" href=\"https://static2.sharepointonline.com/files/fabric/office-ui-fabric-core/11.0.0/css/fabric.min.css\"/> <link href=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" rel=\"stylesheet\"/> <link href=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" rel=\"stylesheet\"/> <link href=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" rel=\"stylesheet\"/> </head> <body class=\"ms-font-m ms-Fabric\"> <div id=\"container\"></div> </body> </html> ";
// Exports
export default code;