import React, { useRef, useState, useEffect, Component } from "react";
import Accordion from 'react-bootstrap/Accordion';

import styles from "./OneGateway.module.css";
import "./OneGateway.module.css";
import OneGatewayItem from "./OneGateway_components/OneGatewayItem";

const OneGateway = ({userProfile}) => {

    const oneGatewayItems = [
        {
            title: "Highlights",
            items: [
                {
                    icon: require("./../../../../../assets/HR_GivingFeedback_icon-05.ico"), 
                    hint: "July-December 2024\nFeedback flows, Engagement Glows! Feedback is a gift, Don't hesitate go give!\nGive your feedback & badge today!", 
                    url: "https://wd3.myworkday.com/lifung/d/task/2997$3067.htmld", 
                    title: "Appreciation Campaign",
                    target: "_blank"
                },
            ]
        },
        {
            title: "AI Apps",
            items: [
                {
                    icon: require("./../../../../../assets/appicon_ai_aiportal.png"), 
                    hint: "Explore various AI tools and tailored bots designed to assist you across multiple domains.", 
                    url: "https://aiportal.lfapps.net/", 
                    title: "LF AI Portal",
                    target: "_blank"
                },
                {
                    icon: require("./../../../../../assets/appicon_ai_designintelligence.png"), 
                    hint: "Revolutionizes the creative process by generating AI designs quickly and easily, boosting efficiency and satisfaction.", 
                    url: "https://aidesign.lfapps.net/", 
                    title: "LF Design Intelligence",
                    target: "_blank"
                },
                {
                    icon: require("./../../../../../assets/appicon_ai_newsai.png"), 
                    hint: "Keeps you updated with the latest news on key customers, improving understanding and service delivery.", 
                    url: "https://newsai.lfapps.net/", 
                    title: "Market News AI",
                    target: "_blank"
                },
                // { 
                //     icon: require("./../../../../../assets/appicon_aichatbot.png"), 
                //     hint: "LF AI Chatbot is a Chatbot that integrates the functionalities of LF ChatGPT, Bing Search, and Text-To-Image.", 
                //     url: "https://teams.microsoft.com/l/app/d4a5f72e-cba2-44a0-ace8-6e36abc4283e", 
                //     title: "LF AI Chatbot",
                //     target: "_blank"
                // },
                // { 
                //     icon: require("./../../../../../assets/appicon_itsdchatbot.png"), 
                //     hint: "ITSD Knowledge Base is AI chatbot that provide IT support and solutions. It serves as a repository of information, troubleshooting guides, and technical advice for IT-related issues.", 
                //     url: "TBC", 
                //     title: "ITSD Chatbot",
                //     target: "_blank"
                // },
                // { 
                //     icon: require("./../../../../../assets/appicon_ainews.png"), 
                //     hint: "Check out the latest news from our leading customers", 
                //     url: "team communicateion site TBA", 
                //     title: "LF AI News",
                //     target: "_blank"
                // },
                { 
                    icon: require("./../../../../../assets/appicon_aihub.png"), 
                    hint: "AI API Gateway", 
                    url: "https://lfaihub-portal.lfapps.net/", 
                    title: "LF AI Hub",
                    target: "_blank"
                },
                // {
                //     icon: require("./../../../../../assets/appicon_ITSupportBot.png"),
                //     hint: "LF IT Support bot",
                //     url: "https://teams.microsoft.com/l/app/d2ad70af-f6f4-4e85-8fc0-c48ccdd998ec",
                //     title: "ITSupportBot",
                //     target: "_blank"
                // },
                {
                    icon: require("./../../../../../assets/appicon_robot.png"),
                    hint: "LF IT Support bot",
                    url: "https://aiportal.lfapps.net/LF_Custom_Bots?assistantID=asst_FZ0MGmlTuDb44d1nV4jW0BEt",
                    title: "ITSupportBot",
                    target: "_blank"
                },
            ]
        },
        {
            title: "Corporate Apps",
            items: [
                { 
                    icon: require("./../../../../../assets/appicon_onefamily.png"), 
                    hint: "Li & Fung Intranet Platform", 
                    url: "https://lfshare.sharepoint.com/sites/OneFamily", 
                    title: "One Family",
                    target: "_blank"
                },
                { 
                    icon: require("./../../../../../assets/appicon_oneservice.png"), 
                    hint: "The IT self-service portal enables employees to find answers and resolve their issues through knowledge library as well as to raise requests for various IT services", 
                    url: "http://oneservice.gtapps.net/", 
                    title: "One Service",
                    target: "_blank"
                },
                { 
                    icon: require("./../../../../../assets/appicon_oneservice_it.png"), 
                    hint: "This IT portal enables IT staff to manage IT related enquiries, issues, requests and other services raised from employees", 
                    url: "http://oneserviceIT.gtapps.net", 
                    title: "One Service (IT Staff)",
                    target: "_blank"
                },
                { 
                    icon: require("./../../../../../assets/appicon_workday.png"), 
                    hint: "New in Sep 2018, Workday, our HR system, provides employees access to their personal information, bank accounts, Payslips etc.", 
                    url: "https://wd3.myworkday.com/lifung/login.html", 
                    title: "Workday",
                    target: "_blank"
                },
            ]
        },
        {
            title: "LFT Data Platform",
            items: [
                { 
                    icon: require("./../../../../../assets/appicon_tableau.png"), 
                    hint: "Self Service Business Intelligence tool to perform data analytics using Tableau Server", 
                    url: "https://bi.lfplatform.com", 
                    title: "BI Tableau",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_dw_report.png"), 
                    hint: "Centralized databank capturing data in Li & Fung core source system for reporting purposes", 
                    url: "https://dwrpt.lfapps.net", 
                    title: "DW Report",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_doc.png"), 
                    hint: "Web-based document management system - database for standardized shipping documents such as LF invoices, supplier invoices, supporting documents, T/T requisition forms, etc", 
                    url: "http://dms.lfapps.net/", 
                    title: "DocMS",
                    target: "_blank"
                }
            ]
        },
        {
            title: "LFT Enterprise Apps",
            items: [
                { 
                    icon: require("./../../../../../assets/appicon_ordermanagement.png"), 
                    hint: "(formerly named as XTS)\nExport Trading System - global office-wide trading system", 
                    url: "http://xts.lfapps.net/", 
                    title: "Order Management",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_search.png"), 
                    hint: "(formerly named as Vendor Search)\nSearch engine to find Vendors, Factories & Customers with the closest fit to your sourcing needs", 
                    url: "https://lfplatform.com/search", 
                    title: "Search",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_compliance.png"), 
                    hint: "Platform to manage Factory Compliance through Audits and Incidents", 
                    url: "https://lfplatform.com/compliance", 
                    title: "Compliance",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_inspection.png"), 
                    hint: "(formerly named as Mobile QC)\nPlatform to manage inspection &amp; other quality control activities.", 
                    url: "https://lfplatform.com/inspection", 
                    title: "Inspection",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_track.png"), 
                    hint: "(formerly named as OTS)\nApplication to monitor and update the status of various work items and milestones.", 
                    url: "https://lfplatform.com/track", 
                    title: "Track",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_eone.png"), 
                    hint: "ERP Application and the Global finance management system", 
                    url: "https://e1.lfapps.net/jde/E1Menu.maf", 
                    title: "EOne",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_group_hfm.png"), 
                    hint: "Group HFM - The Group Financial Consolidation Application for Statutory Reporting", 
                    url: "https://lfthfm.lfapps.net/workspace", 
                    title: "Group HFM",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appiocn_treasuryxpress.png"), 
                    hint: "Treasury Management System", 
                    url: "https://lifung.treasuryxpress.com/", 
                    title: "Tresury Xpress",
                    target: "_blank"
                },
            ]
        },
        {
            title: "Other Apps",
            items: [
                { 
                    icon: require("./../../../../../assets/appicon_fms.png"), 
                    hint: "Freight Management System - Planning, Optimisation & Execution of International Freight Forwarding across Air, Sea & Land", 
                    url: "http://lflmnc.webtracker.wisegrid.net/", 
                    title: "FMS",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_poms.png"), 
                    hint: "Purchase Order Management - Purchase Order Management & Supply Chain visibility", 
                    url: "https://gc.gfsmanagement.net/login", 
                    title: "POMs",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_xchains.png"), 
                    hint: "Core operation application for GFS", 
                    url: "https://xc.gfsmanagement.cn", 
                    title: "Xchains",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_cobalt.png"), 
                    hint: "To serve Cobalt internal application", 
                    url: "https://webapps.cobaltknitwear.com/cbfservices/", 
                    title: "Cobalt E-Services Platform",
                    target: "_blank"
                },
            ]
        },
        {
            title: "Links",
            items: [
                { 
                    icon: require("./../../../../../assets/appicon_lft.png"), 
                    hint: "Home - Li & Fung (lifung.com)", 
                    url: "http://www.lifung.com/",
                    title: "LFT",
                    target: "_blank"
                },{ 
                    icon: require("./../../../../../assets/appicon_lft.png"), 
                    hint: "Companies - Fung Group", 
                    url: "https://funggroup.com/en/our_businesses/companies/", 
                    title: "Fung Group",
                    target: "_blank"
                },
            ]
        }
    ];




    return (
        <Accordion defaultActiveKey={["0","1","2","3","4","5"]} alwaysOpen>
            {oneGatewayItems.map((gatewayItems, index) => (
                <Accordion.Item eventKey={`${index}`} >
                    <Accordion.Header>{gatewayItems.title}</Accordion.Header>
                    <Accordion.Body>
                        {gatewayItems.items.map((item) => (
                            <OneGatewayItem 
                            icon={item.icon} 
                            hint={item.hint} 
                            url={item.url} 
                            title={item.title}
                            target={item.target}
                            userProfile={userProfile}
                            ></OneGatewayItem>
                        ))}
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>

    );
};

export default OneGateway;