// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.oYS5lvnVRuvBM2ohp5vv {\n   font-size: 11px!important;\n}\n\n.Y0ymNTNsrwoSb7imCVgy {\n   min-height: 120px!important;\n}\n\n.u27F2vFsXaJ1X_DUWFtw {\n   vertical-align: middle;\n   font-size: 12px!important;\n}\n\n.oYS5lvnVRuvBM2ohp5vv svg {\n   width: 32px;\n   height: 32px;\n   margin-bottom: 12px!important;\n}\n\n.w6mNvcvXfviyx04CKymg {\n   /* word-break: break-word; */\n   text-align: justify;\n}\n", "",{"version":3,"sources":["webpack://./src/taskpane/components/pages/main/MainPage.module.css"],"names":[],"mappings":";AACA;GACG,yBAAyB;AAC5B;;AAEA;GACG,2BAA2B;AAC9B;;AAEA;GACG,sBAAsB;GACtB,yBAAyB;AAC5B;;AAEA;GACG,WAAW;GACX,YAAY;GACZ,6BAA6B;AAChC;;AAEA;GACG,4BAA4B;GAC5B,mBAAmB;AACtB","sourcesContent":["\n.fs_11 {\n   font-size: 11px!important;\n}\n\n.dropZoneHeight {\n   min-height: 120px!important;\n}\n\n.dropZoneText {\n   vertical-align: middle;\n   font-size: 12px!important;\n}\n\n.fs_11 svg {\n   width: 32px;\n   height: 32px;\n   margin-bottom: 12px!important;\n}\n\n.outputBox {\n   /* word-break: break-word; */\n   text-align: justify;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fs_11": "oYS5lvnVRuvBM2ohp5vv",
	"dropZoneHeight": "Y0ymNTNsrwoSb7imCVgy",
	"dropZoneText": "u27F2vFsXaJ1X_DUWFtw",
	"outputBox": "w6mNvcvXfviyx04CKymg"
};
export default ___CSS_LOADER_EXPORT___;
